<template>
  <div>
    <!--专利托管-->
    <div v-show="nowIndex === 0">
      <div class="right_header">
        <Row :gutter="16">
          <Col span="6">
            <span>专利申请号、专利名称或申请人</span>
            <Input v-model="patentParams.keywords" />
          </Col>
          <Col span="4">
            <span>法律状态</span>
            <Select v-model="patentParams.patentLsnt" :transfer="transfer" filterable style="line-height: 40px!important;">
              <Option value="">全部</Option>
              <Option v-for="(item,index) in lawType" :value="item.lsnt" :key="index">{{item.lsnt}}</Option>
            </Select>
          </Col>
          <Col span="3">
            <span>专利类型</span>
            <Select v-model="patentParams.patentCls" :transfer="transfer">
              <Option value="">全部</Option>
              <Option value="fmzl">发明专利</Option>
              <Option value="syxx">实用新型</Option>
              <Option value="wgzl">外观类型</Option>
            </Select>
          </Col>
          <Col span="11">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 40px;
              "
            >
              <div
                class="button_style"
                style="margin-left: 10px; position: relative; top: 100%"
                @click="searchPatent()"
              >
                检索
              </div>

              <div
                class="button_style"
                style="
                  margin-left: 10px;
                  position: relative;
                  top: 100%;
                  background-color: #fda736;
                "
                @click="generateReport()"
              >
                导出
              </div>

              <div
                class="button_style"
                style="
                  margin-left: 10px;
                  width: 110px;
                  position: relative;
                  top: 100%;
                  background-color: #6e95ff;
                "
                @click="synchronize()"
              >
                同步官方数据
              </div>

              <div
                class="button_style"
                style="
                  margin-left: 10px;
                  position: relative;
                  top: 100%;
                  background-color: #33c26c;
                  width: 100px;
                "
                @click="addWatchP()"
              >
                添加专利监控
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div class="right_real_content">
        <Table border :columns="columns13" :data="data7" :disabled-hover="true">
          <template slot-scope="{ row }" slot="name">
            <strong>{{ row.name }}</strong>
          </template>
          <template slot-scope="{ row, list }" slot="action">
            <Button
              type="primary"
              size="small"
              style="margin-right: 5px; color: rgb(55, 197, 207)"
              @click="costDetail(row)"
              >费用明细
            </Button>
            <span class="operation_span" v-if="row.sellingType == 0">|</span>
            <Button
              type="error"
              size="small"
              @click="sail(row)"
              style="color: rgb(55, 197, 207)"
              v-if="row.sellingType == 0"
              >出售
            </Button>
            <span class="operation_span" v-if="row.state == 1">|</span>
            <Button
              type="error"
              size="small"
              @click="removeWatch(row)"
              style="color: rgb(55, 197, 207)"
              v-if="row.state == 1"
              >移出监控
            </Button>
          </template>
        </Table>
        <Page
          :total="patentTotal"
          show-elevator
          style="margin-top: 50px; float: right"
          @on-change="changePatent"
        />
      </div>
    </div>
    <!--专利监控-->
    <div v-show="nowIndex === 1">
      <div class="right_header" STYLE="padding-top: 50px">
        <Row :gutter="16">
          <Col span="10">
            <Row :gutter="32">
              <Col span="8">
                <Select v-model="buyReplayType" :transfer="transfer">
                  <Option value="0">专利申请号</Option>
                  <Option value="1">专利名称或申请人</Option>
                  <Option value="2">技术领域</Option>
                  <Option value="3">发明人</Option>
                </Select>
              </Col>
              <Col span="16">
                <Input v-model="monitorParams.keywords" />
              </Col>
            </Row>
          </Col>

          <Row>
            <div
              class="button_style"
              style="margin-left: 26px; position: relative"
              @click="searchMonitor()"
            >
              检索
            </div>
          </Row>
        </Row>
      </div>
      <div class="right_real_content">
        <Table
          border
          :columns="columns12"
          :data="data6"
          :disabled-hover="true"
          @on-selection-change="selectMu"
          ref="watchTable"
        >
          <template slot-scope="{ row }" slot="name">
            <strong>{{ row.name }}</strong>
          </template>
          <template slot-scope="{ row, list }" slot="action">
            <Button
              type="primary"
              size="small"
              style="margin-right: 5px; color: rgb(54, 194, 207)"
              @click="addWatch(row)"
            >
              添加监控
            </Button>
          </template>
        </Table>
        <div style="margin-top: 50px; margin-left: 20px; float: left">
          <Checkbox :value="checkAll" @click.prevent.native="handleSelectAll()"
            >全选 </Checkbox
          >&nbsp;&nbsp;<span style="cursor: pointer" @click="mutiAddWatch()"
            >批量添加监控</span
          >
        </div>
        <Page
          :total="monitorTotal"
          show-elevator
          style="margin-top: 50px; float: right"
          @on-change="changeMonitor"
        />
      </div>
    </div>

    <!--  费用明细-->
    <Modal
      id="detailModal"
      v-model="detailModal"
      title="查询费用明细"
      width="1000"
      :footer-hide="footHide"
    >
      <div style="padding: 30px; border-bottom: 1px sOlid #f2f5fa">
        <div style="margin-bottom: 10px">
          <span
            style="
              width: 120px;
              display: inline-block;
              text-align: right;
              margin-right: 20px;
            "
            >费用信息同步时间:</span
          >
          <span>{{feeDetailRow.syncTime}}</span>
          <span
            style="
              width: 120px;
              display: inline-block;
              text-align: right;
              margin-left: 50px;
              margin-right: 20px;
            "
            >专利申请号</span
          >
          <span>{{ feeDetailRow.patentNo }}</span>
        </div>
        <div>
          <span
            style="
              width: 120px;
              display: inline-block;
              text-align: right;
              margin-right: 20px;
            "
            >专利名称:</span
          ><span>{{ feeDetailRow.patentTi}}</span>
        </div>
      </div>
      <div style="padding: 30px">
        <span style="font-size: 16px; color: #333333; font-weight: 400"
          >待缴费信息</span
        >
      </div>
      <span style="font-size: 20px;padding-bottom: 10px;padding-left: 30px">应缴费</span>
      <Table
        border
        :columns="detailColumn"
        :data="detailData1"
        :disabled-hover="true"
        style="margin-bottom: 20px;padding: 0 30px"
      >
        <template slot-scope="{ row }" slot="name">
          <strong>{{ row.name }}</strong>
        </template>
      </Table>
      <span style="font-size: 20px;padding-bottom: 10px;padding-left: 30px">已缴费</span>
      <Table
        border
        :columns="detailColumn2"
        :data="detailData2"
        :disabled-hover="true"
        style="padding: 0 30px"
      >
<!--        <template slot-scope="{ row }" slot="name">-->
<!--          <strong>{{ row.name }}</strong>-->
<!--        </template>-->
      </Table>
    </Modal>

    <!--  专利出售-->
    <Modal
      v-model="sailModal"
      title="出售我的专利"
      width="800"
      :footer-hide="footHide"
    >
      <div style="margin-bottom: 50px">
        <div style="padding: 20px">
          <div
            style="
              width: 95%;
              margin-left: 50%;
              transform: translateX(-50%);
              margin-top: 20px;
              margin-bottom: 30px;
            "
          >
            <Form :model="sellItem" :label-width="100">
              <FormItem label="专利申请号">
                <Input
                  v-model="sellItem.patentNo"
                  placeholder="请输入专利申请号"
                ></Input>
              </FormItem>
              <FormItem label="专利名称">
                <Input
                  v-model="sellItem.patentTi"
                  placeholder="请输入专利名称"
                ></Input>
              </FormItem>
              <FormItem label="出售价格">
                <Input
                  v-model="sellItem.price"
                  placeholder="请输入出售价格"
                ></Input>
              </FormItem>
              <FormItem label="联系人">
                <Input
                  v-model="sellItem.contactMan"
                  placeholder="请输入联系人"
                ></Input>
              </FormItem>
              <FormItem label="联系电话">
                <Input
                  v-model="sellItem.contactNo"
                  placeholder="请输入联系电话"
                ></Input>
              </FormItem>
            </Form>
          </div>
          <div style="margin-left: 50px">
            <div
              class="button_style"
              style="
                margin-left: 26px;
                width: 80px;
                position: relative;
                top: 50%;
              "
              @click="submitSellPatent"
            >
              提交
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <!--添加监控确认-->
    <Modal
      v-model="watchModal"
      title="添加监控确认"
      width="400"
      :footer-hide="footHide"
    >
      <div>
        <div style="padding: 20px">
          <div
            style="
              width: 95%;
              margin-left: 50%;
              transform: translateX(-50%);
              margin-top: 20px;
              text-align: center;
            "
          >
            <div style="margin-bottom: 5px">
              <span>您确定要将该专利加入监控范围？</span>
            </div>
            <div
              style="
                margin-bottom: 25px;
                font-size: 12px;
                color: rgb(54, 194, 207);
              "
            >
              <span>后续该专利的信息变更将进行通知</span>
            </div>
            <div>
              <div
                class="button_style"
                style="
                  margin-left: 26px;
                  width: 80px;
                  position: relative;
                  top: 50%;
                "
                @click="watchModal = false"
              >
                我再想想
              </div>
              <div
                class="button_style"
                style="
                  margin-left: 26px;
                  width: 80px;
                  position: relative;
                  top: 50%;
                  background-color: #fda736;
                "
                @click="confirmAddWacth()"
              >
                确定
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>

    <!--移除监控确认-->
    <Modal
      v-model="removeWatchModal"
      title="移出监控确认"
      width="400"
      :footer-hide="footHide"
    >
      <div>
        <div style="padding: 20px">
          <div
            style="
              width: 95%;
              margin-left: 50%;
              transform: translateX(-50%);
              margin-top: 20px;
              text-align: center;
            "
          >
            <div style="margin-bottom: 5px">
              <span>您确定要将该专利移出监控范围？</span>
            </div>
            <div
              style="
                margin-bottom: 25px;
                font-size: 12px;
                color: rgb(54, 194, 207);
              "
            >
              <span>后续该专利的信息变更将不再通知</span>
            </div>
            <div>
              <div
                class="button_style"
                style="
                  margin-left: 26px;
                  width: 80px;
                  position: relative;
                  top: 50%;
                "
                @click="removeWatchModal = false"
              >
                我再想想
              </div>
              <div
                class="button_style"
                style="
                  margin-left: 26px;
                  width: 80px;
                  position: relative;
                  top: 50%;
                  background-color: #fda736;
                "
                @click="confirmRemoveWatch()"
              >
                确定
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>

    <!--同步结果-->
    <Modal
      v-model="resultsModal"
      title="同步结果"
      width="500"
      :footer-hide="footHide"
    >
      <div>
        <div style="padding: 20px">
          <div
            style="
              width: 95%;
              margin-left: 50%;
              transform: translateX(-50%);
              margin-top: 20px;
              text-align: center;
            "
          >
            <div
              style="
                margin-bottom: 25px;
                width: 80%;
                height: 35px;
                margin-left: 50%;
                transform: translateX(-50%);
                line-height: 35px;
                font-size: 14px;
                background-color: rgb(222, 251, 249);
                color: rgb(55, 197, 207);
              "
            >
              <span>专利费用与法律费用信息同步完成</span>
            </div>
            <div style="margin-bottom: 10px; font-size: 12px">
              <span>同步专利数：&nbsp;{{ this.waitNum }}</span>
            </div>
            <div style="margin-bottom: 10px; font-size: 12px">
              <span>完成同步专利数：&nbsp;{{this.successNum}}</span>
            </div>
            <div style="margin-bottom: 10px; font-size: 12px">
              <span>异常同步专利数：&nbsp;{{ this.errorNum }}</span
              ><span style="color: rgb(54, 194, 207)"
                >(系统后台仍将定时同步信息)</span
              >
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import axios from "axios";
import { getLocalToken } from "../../../plugins/tools/storage";
import {
  isCorrectPassword,
  isPhoneNumber,
  successToast,
  warnToast,
  errorToast,
} from "@/plugins/tools/util";
import { patentUrl } from "../../../plugins/http/baseUrl";
import {
  getLawType,
  getClaimPatentList,
  monitorAdd,
  getMonitorList,
  saveBatchSelling,
  monitorUpdate,
  monitorSyncInfo,
  monitorSyncFee,
} from "../../../plugins/api/patentTrustee";

export default {
  name: "PatentTrusteeshipCom",
  data() {
    return {
      transfer: true,
      addMonitorItem: {},
      buyReplayType:"0",
      deleteId: "",
      waitNum:0,
      successNum:0,
      errorNum:0,
      sellItem: {
        contactMan: "",
        contactNo: "",
        patentNo: "",
        patentTi: "",
        price: "",
      },
      lawType:[],
      monitorTotal: 10,
      patentTotal: 10,
      // 专利托管params
      patentParams: {
        keywords: "",
        patentLsnt: "",
        patentCls: "",
        pageNum: 1,
        pageSize: 10,
      },

      selection: [],
      // 监控form
      monitorParams: {
        keywords: "",
        pageNum: 1,
        pageSize: 10,
      },
      nowIndex: 0,
      detailModal: false,
      footHide: false,
      checkAll: false,
      removeWatchModal: false,
      sailModal: false,
      watchModal: false,
      resultsModal: false,
      // 专利托管
      data7: [],
      columns13: [
        {
          title: "专利申请号",
          key: "patentNo",
          align: "center",
          // render: (h, params) => {
          //   return h('div', [
          //     h('span', {
          //     style: {
          //       display: 'inline-block',
          //       width: '100%',
          //       overflow: 'hidden',
          //       textOverflow: 'ellipsis',
          //       whiteSpace: 'nowrap'
          //     },
          //     domProps: {
          //       title: params.row.patentNo
          //     }
          //   }, params.row.patentNo)
          // ])
          // }
        },
        {
          title: "专利名称",
          key: "patentTi",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  style: {
                    display: '-webkit-box',
                    width: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    '-webkit-line-clamp': '2',
                    '-webkit-box-orient': 'vertical'
                  },
                  domProps: {
                    title: params.row.patentTi,
                  },
                },
                params.row.patentTi
              ),
            ]);
          },
        },
        {
          title: "申请人",
          key: "patentPa",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  style: {
                    display: '-webkit-box',
                    width: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    '-webkit-line-clamp': '2',
                    '-webkit-box-orient': 'vertical'
                  },
                  domProps: {
                    title: params.row.patentPa,
                  },
                },
                params.row.patentPa
              ),
            ]);
          },
        },
        {
          title: "申请日",
          key: "patentAd",
          align: "center",
        },
        {
          title: "缴费截止日",
          key: "maxFeeDate",
          align: "center",
        },
        {
          title: "法律状态",
          key: "patentLsnt",
          align: "center",
        },
        {
          title: "应缴费",
          key: "sumShouldFee",
          align: "center",
          width: "80",
        },
        {
          title: "操作",
          slot: "action",
          width: 250,
          align: "center",
        },
      ],
      //缴费表格1
      detailColumn: [
        {
          title: "费用种类",
          align: "center",
          key: "fee_type",
        },
        {
          key: "should_fee",
          title: "应缴费金额",
          align: "center",
        },
        {
          key: "fee_date",
          title: "缴费截止日",
          align: "center",
        },
        {
          key: "fee_status",
          title: "费用状态",
          align: "center",
        },
        {
          key: "dajiao_status",
          title: "代缴状态",
          align: "center",
        }
      ],
      detailData1: [],
      feeDetailRow:{},
      detailColumn2: [
        {
          title: "收据号",
          align: "center",
          key: "rec_no",
        },
        {
          key: "paid_fee",
          title: "缴费金额",
          align: "center",
        },
        {
          key: "fee_date",
          title: "缴费日",
          align: "center",
        },
        {
          key: "fee_type",
          title: "费用类型",
          align: "已缴费项",
        }
      ],
      detailData2: [],
      //专利监控
      data6: [],
      columns12: [
        {
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "专利申请号",
          key: "applicationNumber",
          align: "center",
        },
        {
          title: "专利名称",
          key: "title",
          align: "center",
        },
        {
          title: "专利类型",
          key: "type",
          align: "center",
        },
        {
          title: "申请人",
          key: "applicant",
          align: "center",
          render: (h, params) => {
            return h('div', [
              h('span', {
                style: {
                  display: '-webkit-box',
                  width: '100%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  '-webkit-line-clamp': '2',
                  '-webkit-box-orient': 'vertical'
                },
                domProps: {
                  title: params.row.applicant
                }
              }, params.row.applicant)
            ])
          }
        },
        {
          title: "发明人",
          key: "inventor",
          align: "center",
          render: (h, params) => {
            return h('div', [
              h('span', {
                style: {
                  display: '-webkit-box',
                  width: '100%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  '-webkit-line-clamp': '2',
                  '-webkit-box-orient': 'vertical'
                },
                domProps: {
                  title: params.row.inventor
                }
              }, params.row.inventor)
            ])
          }
        },
        {
          title: "操作",
          slot: "action",
          width: 100,
          align: "center",
        },
      ],
    };
  },
  methods: {
    // 下载报告
    async generateReport() {
      let json = await axios({
        method: "post",
        url: `${patentUrl}/monitor/export`,
        data: this.patentParams,
        responseType: "arraybuffer",
        headers: { token: getLocalToken() },
      });
      let fileName = "报告.xls";
      if(json.status == 200) {
        let blob = new Blob([json.data], {
          type: "application/vnd.ms-excel};charset=utf-8",
        });
        let downloadElement = document.createElement("a");
        let href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = fileName;
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      }else {
          errorToast(json.message)
      }
    },

    //专利查询
    async searchPatent() {
      this.patentParams.pageNum = 1;
      this.getPatentList();
    },
    // 出售专利
    async submitSellPatent() {
      for (let key in this.sellItem) {
        if (this.sellItem[key] === "") {
          warnToast("请将出售信息填满");
          return "";
        }
      }
      console.log(this.sellItem)
      if (!isPhoneNumber(this.sellItem["contactNo"])) {
        warnToast("请填写正确的号码");
        return "";
      }
      let json = await saveBatchSelling([this.sellItem]);
      if (json.code == 0) {
        for (let key in this.sellItem) {
          this.sellItem[key] = "";
        }
        successToast("出售成功");
        this.getPatentList();
      } else {
        warnToast(json.message);
      }
      this.sailModal = false;
    },

    // 监管查询
    async searchMonitor() {
      this.monitorParams.pageNum = 1;
      this.getClaimPatent();
    },

    // 专利托管翻页
    async changePatent(index) {
      this.patentParams.pageNum = index;
      this.getPatentList();
    },

    // 监控翻页
    async changeMonitor(index) {
      this.monitorParams.pageNum = index;
      this.getClaimPatent();
    },

    // 托管列表
    async getPatentList() {
      let json = await getMonitorList(this.patentParams);

      if (json.code == 0) {
        this.data7 = json.result.list;
        this.patentTotal = json.result.total;
        this.data7.forEach((item, index) => {
          if(item.maxFeeDate){
            let dateee = new Date(item.maxFeeDate).toJSON();
            item.maxFeeDate = new Date(+new Date(dateee) + 8 * 3600 * 1000)
                .toISOString()
                .replace(/T/g, " ")
                .replace(/\.[\d]{3}Z/, "")
                .split(" ")[0];
          }else{
            item.maxFeeDate == '-- --'
          }

          let dateee2 = new Date(item.patentAd).toJSON();
          item.patentAd = new Date(+new Date(dateee2) + 8 * 3600 * 1000)
            .toISOString()
            .replace(/T/g, " ")
            .replace(/\.[\d]{3}Z/, "")
            .split(" ")[0];
        });
      } else {
        errorToast(json.message);
      }
      // this.$parent.changeHeight();
    },

    // 监控列表
    async getClaimPatent() {
      if(this.monitorParams.keywords == ""){
        warnToast("请填写查询关键词")
      }else{
        let json = await getClaimPatentList(this.monitorParams);
        if (json.code == 0) {
          this.data6 = json.result.patents;
          this.monitorTotal = json.result.total
        } else {
          warnToast(json.message);
        }
        // this.$parent.changeHeight();
      }

    },

    // 跳转监控
    async addWatchP() {
      // this.getClaimPatent();
      this.nowIndex = 1;
    },

    // 移除监控
    async confirmRemoveWatch() {
      let data = {
        state: 0,
        delFlag: 0,
        id: this.deleteId,
      };
      let json = await monitorUpdate([data]);
      if (json.code == 0) {
        successToast("移除成功");
        this.getPatentList();
      } else {
        warnToast(json.message);
      }
      this.removeWatchModal = false;
    },

    // 查看明细
    async costDetail(row) {
      this.feeDetailRow = row;
      let dateee = new Date(this.feeDetailRow.syncTime).toJSON();
      this.feeDetailRow.syncTime = new Date(+new Date(dateee) + 8 * 3600 * 1000)
          .toISOString()
          .replace(/T/g, " ")
          .replace(/\.[\d]{3}Z/, "")
          .split(" ")[0];
      let json = await monitorSyncFee({ id: row.id, patentNo: row.patentNo });
      if (json.code == 0) {
        if(json.result["yijiaofList"]){
          this.detailData2 = json.result["yijiaofList"];
        }
       if(json.result["yingjiaofList"]){
         this.detailData1 = json.result["yingjiaofList"];
         this.detailData1.forEach((item) => {
           item['dajiao_status'] = "/"
         })
       }

        this.detailModal = true;
      } else {
        errorToast(json.message);
      }
    },

    // 同步
    async synchronize() {
      let json = await monitorSyncInfo(this.patentParams);
      if(json.code == 0){
        this.resultsModal = true;
        this.waitNum = json.result.waitNum;
        this.successNum = json.result.successNum;
        this.errorNum = json.result.errorNum;
      }else{
        errorToast(json.message);
      }

    },

    sail(row) {
      this.sellItem.patentTi = row.patentTi;
      this.sellItem.patentNo = row.patentNo
      this.sailModal = true;
    },

    removeWatch(row) {
      this.deleteId = row.id;

      this.removeWatchpatentPa;
      this.removeWatchModal = true;
    },

    addWatch(row) {
      this.watchModal = true;
      this.addMonitorItem = row;
    },

    async confirmAddWacth() {
      // 单条添加监控
      if (this.selection.length === 0) {
        let data = {};
        data.patentNo = this.addMonitorItem.applicationNumber;
        data.patentTi = this.addMonitorItem.title;
        data.patentPa = this.addMonitorItem.applicant;
        data.patentAd = this.addMonitorItem.applicationDate;
        data.patentCls = this.addMonitorItem.type;
        data.type = 0;
        data.patentLsnt = this.addMonitorItem.legalStatus;
        let json = await monitorAdd([data]);
        if (json.code == 0) {
          successToast(json.result.syncMgs);
          this.getClaimPatent();
          this.watchModal = false;
        } else {
          warnToast(json.message);
          this.watchModal = false;
        }
      } else {
        // 多条添加监控
        let submitData = [];
        this.selection.forEach((item, index) => {
          let data = {};
          data.patentNo = item.applicationNumber;
          data.patentTi = item.title;
          data.patentPa = item.applicant;
          data.patentAd = item.applicationDate;
          data.patentCls = item.type;
          data.type = 0;
          data.patentLsnt = item.legalStatus;
          submitData.push(data);
        });
        let json = await monitorAdd(submitData);
        if (json.code == 0) {
          successToast(json.result.syncMgs);
          this.getClaimPatent();
          this.watchModal = false;
        } else {
          warnToast(json.message);
          this.watchModal = false;
        }
      }
    },

    handleSelectAll(status) {
      this.checkAll = !this.checkAll;
      this.$refs.watchTable.selectAll(this.checkAll);
    },

    selectMu(selection) {
      this.selection = selection;
    },
    mutiAddWatch() {
      if (this.selection.length === 0) {
        warnToast("请选择至少一条");
      } else {
        this.watchModal = true;
      }
    },
  },

  mounted() {
    this.getPatentList();
    getLawType().then((res) => {
      if(res.code == 200){
        this.lawType = res.data
      }
    })
  },
};
</script>

<style scoped lang="scss">
/deep/ .ivu-modal-body {
  height: auto !important;
  max-height: 400px !important;
  overflow-y: scroll !important;
}

/deep/ .ivu-table-tbody tr:nth-child(2n) td {
  background-color: #f3fcfc !important;
}

/deep/ td {
  border-right: none !important;
}

.right_real_content {
  margin-bottom: 50px;
  width: 95%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 40px;
  overflow: hidden;
  padding-bottom: 10px;
}

.right_header {
  width: 95%;
  padding-top: 20px;
  height: 140px;
  position: relative;
  margin-bottom: 40px !important;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 2px dotted #f5f5f5;
  overflow: hidden;

  span {
    display: block;
    margin-bottom: 20px;
  }
}

.button_style {
  cursor: pointer;
  font-size: 14px;
  margin-left: 10px;
  display: inline-block;
  border-radius: 2px;
  background-color: rgb(54, 194, 207);
  width: 80px;
  height: 40px;
  z-index: 51;
  line-height: 40px;
  text-align: center;
  color: white;
  cursor: pointer;
  padding: 0 5px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.input_search,
.input_search:focus {
  border-width: 1px;
  border-color: rgb(55, 197, 207);
  border-style: solid;
  border-radius: 2px;
  background-color: rgb(255, 255, 255);
  box-shadow: 6px 0px 15px 0px rgba(127, 127, 127, 0.1);
  width: 300px;
  height: 40px;
  outline: none;
}
</style>
